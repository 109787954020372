import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { IoPersonSharp } from "react-icons/io5";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import logo from "../../constants/SCLogo.png";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import { AuthActionTypes } from "../../redux/action-types";
import { connect } from "react-redux";
import AdminAddPatient from "../../pages/admin-add-patient";
import MyModal from "../ui/app-modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const drawerWidth = 220;

const NavbarSuperAdmin = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [formSave, setFormSave] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  // console.log(props.userData.hospital.hospitalName);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = (props) => {
    let r = window.confirm("Logout from dashboard?");
    if (r == true) {
      window.localStorage.removeItem("netowrkID");
      props.logout();
    }
  };

  return (
    <div className={classes.root} style={{ backgroundColor: "white" }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "white" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div>
          <Toolbar>
            <IconButton
              style={{
                color: "black",
                marginLeft: "-20px",
                marginRight: "24px",
              }}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  className="size-full aligncenter"
                  src={logo}
                  alt=""
                  width="60"
                  height="40"
                  marginRight="60px"
                />
                <Typography
                  component="h1"
                  variant="h6"
                  color="black"
                  noWrap
                  style={{ flex: "grow" }}
                ></Typography>
                <a
                  class="nav-link disabled smallHidden"
                  href="#"
                  tabIndex="-1"
                  aria-disabled="true"
                  style={{
                    color: "black",
                    marginLeft: "3px",
                    fontWeight: "700",
                  }}
                >
                  {props.userData && props.userData.userRole === 3 ? (
                    "Super Admin dashboard"
                  ) : (
                    <>
                      {props.userData && props.userData.hospital ? (
                        <div>
                          {props.userData.hospital.hospitalName} Admin dashboard
                        </div>
                      ) : (
                        "Admin dashboard"
                      )}
                    </>
                  )}
                </a>
              </div>

              <div style={{ display: "flex", fontWeight: "700" }}>
                <a
                  class="nav-link disabled"
                  href="#"
                  tabIndex="-1"
                  aria-disabled="true"
                  style={{ color: "black", marginRight: "20px" }}
                >
                  {props.userData && props.userData.firstName}
                </a>

                {props.userData && props.userData.userRole === 3 ? (
                  <></>
                ) : (
                  <>
                    {props.userData.hospital &&
                    window.location.pathname === "/dashboard" ? (
                      <div>
                        <button
                          type="button"
                          className="btn "
                          style={{
                            marginRight: "10px",
                            alignItems: "right",
                            backgroundColor: "rgb(29, 117, 189)",
                            color: "rgb(255, 255,255)",
                          }}
                          // onClick={() => setOpenModal(true)}
                          onClick={() => history.push(`/adminaddpatient`)}
                        >
                          + Add Patient
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <button
                  type="button"
                  className="btn "
                  style={{
                    marginRight: "10px",
                    alignItems: "right",
                    backgroundColor: "rgb(29, 117, 189)",
                    color: "rgb(255, 255,255)",
                  }}
                  onClick={() => logout(props)}
                >
                  Log Out
                </button>
              </div>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            title={"Dashboard"}
            onClick={() => history.push("/dashboard")}
          >
            <DashboardIcon style={{ cursor: "pointer" }} />
            <ListItemText
              primary="Dashboard"
              style={{ marginLeft: "35px", cursor: "pointer" }}
            />
          </ListItem>
          {props.userData && props.userData.userRole === 0 ? (
            <>
              <ListItem
                title={"DeletePatinent"}
                onClick={() => history.push("/deletepatinent")}
              >
                <AccountCircleIcon style={{ cursor: "pointer" }} />
                <ListItemText
                  primary="Deleted Patients"
                  style={{ marginLeft: "35px", cursor: "pointer" }}
                />
              </ListItem>
              <ListItem
                title={"Profile"}
                onClick={() => history.push("/adminprofile")}
              >
                <PersonIcon style={{ cursor: "pointer" }} />
                <ListItemText
                  primary="Profile"
                  style={{ marginLeft: "35px", cursor: "pointer" }}
                />
              </ListItem>
            </>
          ) : (
            <></>
          )}

          {props.userData && props.userData.userRole === 3 ? (
            <ListItem
              title={"Add Organization"}
              onClick={() => history.push("/doctorform")}
            >
              <LocalHospitalIcon style={{ cursor: "pointer" }} />
              <ListItemText
                primary="Add Organization"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              />
            </ListItem>
          ) : (
            <></>
          )}

          {props.userData && props.userData.userRole === 3 ? (
            <ListItem
              title={"Add Admin"}
              onClick={() => history.push("/addadmin")}
            >
              <SupervisorAccountIcon style={{ cursor: "pointer" }} />
              <ListItemText
                primary="Add Admin"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              />
            </ListItem>
          ) : (
            <></>
          )}

          {props.userData && props.userData.userRole === 3 ? (
            <ListItem
              title={"App Network"}
              onClick={() => history.push("/appnetwork")}
            >
              <SettingsEthernetIcon style={{ cursor: "pointer" }} />
              <ListItemText
                primary="App Network"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              />
            </ListItem>
          ) : (
            <></>
          )}
        </List>
      </Drawer>
    </div>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch({
        type: AuthActionTypes.LOGOUT,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarSuperAdmin);
