import React from "react";
import { connect } from "react-redux";
import AddAdminForm from "../../auth/AddAdminForm/AddAdminForm";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";

const AdminProfile = (props) => {
  console.log("AdminProfile props:", props);
  console.log("props:", props.userData.emailId);

  let patientData;

  if (props.userData && props.userData?.network?.members?.length) {
    patientData = props.userData.network.members.find(
      (el) => el._id === props.userData._id
    );
    patientData = {
      ...patientData,
      networkName: props.userData?.network?.networkName,
    };

    if (props.userData.hospital) {
      patientData = {
        ...patientData,
        hospital: props.userData.hospital.hospitalName,
      };
    }
    if (props.userData.relationship) {
      patientData = {
        ...patientData,
        relationship: props.userData.relationship.name,
      };
    }
  }

  console.log("patientData", patientData);
  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Your Profile</GoToBack>
        <AddAdminForm
          isTitle={false}
          isSuperAdmin={props.userData.hospital}
          isReadOnly={true}
          patientData={patientData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

export default connect(mapStateToProps)(AdminProfile);
